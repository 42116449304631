/* Dashboard */

.transaparent-tab-border {
  border-bottom: 1px solid $nav-tabs-border-color
  }
  .graph-custom-legend {
    ul {
      display: flex;
      align-items: center;
      float: right;
      li {
        display: flex;
        align-items: center;
        list-style: none;
        margin-left: 1rem;
        .legend-box {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          margin-right: 10px;
          display: inline-block;
        }
        &:first-child {
          margin-left: 0px;
        }
      }
      @media (max-width: 991px) {
        float: left;
        padding-left: 0;
      }
    }
    &.primary-dot {
      ul {
        li {
          .legend-box {
            background: theme-color(primary);
          }
        }
      }
    }
    
  }
  .card-danger-gradient {
    background: $card-mixed-danger;
  }
  .recent-activity {
    .activity-info {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      text-align: center;
      color: $white;
      padding-top: 5px;
      font-weight: bold;
      margin-right: 1.25rem;
      position: relative;
      &:after {
        content: '';
        width: 2px;
        height: 54px;
        position: absolute;
        left: 50%;
        top: 33px;
        border-right: 1px dashed $border-color;
      }
      &.hide-border {
        &:after {
          display: none;
        }
  
      }
    }
  }
  
  .rtl {
    .graph-custom-legend {
      ul {
        float: left;
        padding-left: 0;
        li {
          margin-left: 0;
          margin-right: 1rem;
          .legend-box {
            margin-right: 0;
            margin-left: .625rem;
          }
        }
      }
    }
    .activity-info {
      margin-left: 1.25rem;
      margin-right: 0;
    }
  }
  
  .dotted-border {
    border: 1px dashed $border-color;
  }
  .legend-horizontal {
    display: flex;
    padding: 0;
    li {
      list-style: none;
      font-size: .875rem;
      color: theme-color(dark);
      .legend-dots {
        width: .75rem;
        height: .75rem;
        border-radius: 100%;
        display: inline-block;
        margin-right: .5rem;
        margin-left: .5rem;
      }
    }
  }
  .CircularProgressbar {
    .CircularProgressbar-path {
      stroke-width: 5px;
    }
    .CircularProgressbar-trail {
      stroke: #eee;
      stroke-width: 5px;
    }
    .CircularProgressbar-background {
      fill: green;
    }
  }
  .CircularProgressbar {
    &.progress-order {
      .CircularProgressbar-path {
        stroke: url(#progress-order);
      }
    }
  }
  .CircularProgressbar {
    &.progress-visitors {
      .CircularProgressbar-path {
        stroke: url(#progress-visitors);
      }
    }
  }
  .CircularProgressbar {
    &.progress-impressions {
      .CircularProgressbar-path {
        stroke: url(#progress-impressions);
      }
    }
  }
  .CircularProgressbar {
    &.progress-followers {
      .CircularProgressbar-path {
        stroke: url(#progress-followers);
      }
    }
  }
  .conversion-border {
    border-right: 1px solid $border-color;
    .rtl & {
      border-right: none;
      border-left: 1px solid $border-color;
    }
    @media (max-width: 1024px) { 
      border:none;
      .rtl & {
        border:none;
      }
    }
  }